<template>
    <div id="MemberSystemRegister" class="login-form-style">
        <section class="member-title-section-style">
            <h1>アカウント新規作成</h1>
        </section>
        <section id="step1" v-if="stepController === 1">
            <ElForm
                :label-position="store.getters.labelPosition"
                label-width="260px"
                ref="registerFormRef"
                :model="registerFormData"
                :rules="registerFormRules"
            >
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス"
                    prop="email"
                >
                    <ElInput
                        v-model="registerFormData.email"
                        type="email"
                    ></ElInput>
                </ElFormItem>
                <br />
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス（確認用）"
                    prop="emailCheck"
                >
                    <ElInput
                        v-model="registerFormData.emailCheck"
                        type="email"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="パスワード"
                    prop="password"
                >
                    <ElInput
                        v-model="registerFormData.password"
                        type="password"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="パスワード（確認用）"
                    prop="passwordCheck"
                >
                    <ElInput
                        v-model="registerFormData.passwordCheck"
                        type="password"
                    ></ElInput>
                </ElFormItem>
                <p class="notes f14 f14-mb">
                    【重要】2010年以前に作成されたdocomo及びauのメールアドレスは、ご登録いただけない場合がございます。<br />
                    次のようなメールアドレスはご登録いただけません：<br />
                    ・メールアドレスの先頭もしくは@の直前に記号が入っている<br />
                    ・記号が２文字以上連続している<br />
                    ・@より前に「半角英数字と一部の記号※」以外の文字が入っている<br />
                    &emsp;※一部の記号&emsp;. ! # $ % & ‘ * + – / = ? ^ _ { | } ~
                </p>
                <div class="button-wrap">
                    <button
                        class="f18 f16-mb"
                        @click.prevent="submitRegisterForm"
                    >
                        次へ
                    </button>
                </div>
                <div class="sub-button-wrap">
                    <router-link
                        :to="{ name: 'MemberSystemLogin' }"
                        class="f14 f12-mb"
                        >アカウントをお持ちの方はログイン</router-link
                    >
                </div>
            </ElForm>
        </section>
        <section id="step2" v-else-if="stepController === 2">
            <ElForm
                :label-position="store.getters.labelPosition"
                label-width="200px"
                ref="userDetailsFormRef"
                :model="userDetailsFormData"
                :rules="userDetailsFormRules"
            >
                <ElFormItem class="f14 f14-mb" label="名前" prop="name">
                    <ElInput
                        v-model="userDetailsFormData.name"
                        type="name"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="ふりがな" prop="furigana">
                    <ElInput
                        v-model="userDetailsFormData.furigana"
                        type="furigana"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="生年月日" prop="birth">
                    <ElDatePicker
                        v-model="userDetailsFormData.birth"
                        type="date"
                        format="YYYY/MM/DD"
                        value-format="YYYY/MM/DD"
                    ></ElDatePicker>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="電話番号" prop="phone">
                    <ElInput
                        v-model="userDetailsFormData.phone"
                        type="phone"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="住所" prop="address">
                    <ElInput
                        v-model="userDetailsFormData.address"
                        type="address"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="紹介者"
                    prop="recommender"
                >
                    <ElInput
                        v-model="userDetailsFormData.recommender"
                        type="recommender"
                    ></ElInput>
                </ElFormItem>

                <div class="button-wrap">
                    <button
                        class="f18 f16-mb"
                        @click.prevent="submitUserDetailsForm"
                    >
                        登録
                    </button>
                </div>
            </ElForm>
        </section>
    </div>
</template>
<script>
export default {
    name: 'MemberSystemRegister',
}
</script>
<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { checkEmail, userRegister } from '@/service/api'
import {
    getRegisterFormRules,
    getUserDetailsFormRules,
} from '@/utils/formRules'
import { ElMessageBox } from 'element-plus'
const store = useStore()
const stepController = ref(1)

const registerFormRef = ref(null)
const registerFormData = reactive({
    email: '',
    password: '',
})
const registerFormRules = getRegisterFormRules(registerFormData)
function submitRegisterForm() {
    registerFormRef.value.validate(async (valid) => {
        if (valid) {
            let { data } = await checkEmail(registerFormData.email)
            if (data) {
                ElMessageBox.alert(
                    'メールアドレスはすでに存在します。別のメールアドレスを使用してログインください。',
                    'メールアドレスは既に存在しています。',
                    {
                        confirmButtonText: 'OK',
                    }
                )
            } else {
                stepController.value += 1
            }
        }
    })
}

const userDetailsFormRef = ref(null)
const userDetailsFormData = reactive({
    name: '',
    birth: '',
    phone: '',
    address: '',
    jog: '',
    memberId: '',
    qualification: '',
    role: '',
    recommender: '',
    location: '',
    furigana: '',
})
const userDetailsFormRules = getUserDetailsFormRules(userDetailsFormData)
function submitUserDetailsForm() {
    userDetailsFormRef.value.validate(async (valid) => {
        if (valid) {
            await userRegister(registerFormData, userDetailsFormData)
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
</style>
